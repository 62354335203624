<template>
    <!-- Social Media -->
    <div
        class="fixed top-0 left-0 z-20 flex-col justify-center hidden w-32 h-screen p-12 pointer-events-none gap-y-5 text-primary mix-blend-difference xl:flex"
    >
        <a
            href="https://github.com/raphaelbernhart"
            target="_blank"
            rel="noopener noreferrer"
            class="w-full transition-colors cursor-pointer pointer-events-auto hover:text-gray-400"
        >
            <Icon name="bxl:github" class="w-full h-full" />
        </a>
        <a
            href="https://dribbble.com/raphaelbernhart"
            target="_blank"
            rel="noopener noreferrer"
            class="w-full transition-colors cursor-pointer pointer-events-auto hover:text-gray-400"
        >
            <Icon name="bxl:dribbble" class="w-full h-full" />
        </a>
        <a
            href="https://vimeo.com/raphaelbernhart"
            target="_blank"
            rel="noopener noreferrer"
            class="w-full transition-colors cursor-pointer pointer-events-auto hover:text-gray-400"
        >
            <Icon name="bxl:vimeo" class="w-full h-full" />
        </a>
    </div>
</template>
