<template>
    <Transition name="navigationMenu">
        <!-- Navigation Menu -->
        <div
            v-show="navigationStore.isOpen === true"
            class="fixed top-0 left-0 z-30 w-screen h-screen text-primary"
            :class="{ 'pointer-events-none': navigationStore.isOpen === false }"
        >
            <div class="relative w-screen h-screen px-10 py-12">
                <div
                    class="container relative z-10 flex items-center h-full px-0 sm:px-2"
                >
                    <div
                        class="grid w-full grid-cols-6 pt-24 gap-y-12 sm:pt-0 sm:gap-y-0"
                    >
                        <div
                            ref="navigationLinksContainer"
                            class="flex flex-col items-start col-span-6 md:col-span-4 gap-y-4 md:gap-y-8"
                        >
                            <NavigationLink text="Home" route="/" :index="0" />
                            <NavigationLink
                                text="Works"
                                route="/works"
                                :index="1"
                            />
                            <NavigationLink
                                text="Profile"
                                route="/profile"
                                :index="2"
                            />
                            <!-- <NavigationLink text="Contact" route="/contact" :index="3" /> -->
                        </div>
                        <div class="hidden col-span-6 xs:block md:col-span-2">
                            <div
                                ref="furtherInfosContainer"
                                class="flex flex-col h-full text-lg sm:justify-center gap-x-16 gap-y-6 sm:gap-y-16 text-primary"
                            >
                                <div
                                    class="flex-col items-start hidden xs:flex gap-y-1 sm:gap-y-2"
                                >
                                    <span class="font-bold">Say Hi!</span>
                                    <a
                                        class="inline-block underline"
                                        href="mailto:mail@raphaelbernhart.at"
                                    >
                                        mail@raphaelbernhart.at
                                    </a>
                                </div>
                                <div class="flex flex-col gap-y-1 sm:gap-y-2">
                                    <span class="font-bold">Social Media</span>
                                    <WidgetSocialMedia />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Background -->
                <div
                    ref="navMenuBg"
                    class="absolute top-0 left-0 w-screen h-screen bg-primary"
                ></div>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
const navigationStore = useNavigationStore();

// Refs
const navigationLinksContainer = ref(null);
const furtherInfosContainer = ref(null);
const navMenuBg = ref(null);

// Animations
const menuTl = useGsap.timeline({
    paused: true,
});

const initializeAnimation = () => {
    navigationStore.$subscribe((mutation, state) => {
        menuTl.play();
        if (state.isOpen === false) {
            menuTl.reverse();
        }
    });

    // Background
    menuTl.fromTo(
        navMenuBg.value,
        {
            width: 0,
        },
        {
            width: '100%',
            duration: 0.75,
            ease: 'power1.inOut',
        },
    );

    // Navigationlinks
    menuTl.fromTo(
        Array.from((navigationLinksContainer.value as any)?.childNodes).filter(
            (el: unknown) => {
                if (el instanceof HTMLElement && el.nodeType === 1) {
                    return true;
                }
                return false;
            },
        ),
        {
            opacity: 0,
            x: -75,
        },
        {
            opacity: 1,
            x: 0,
            stagger: 0.05,
        },
        0.3,
    );

    // furtherInfos
    const furtherInfosChildNodes = Array.from(
        (furtherInfosContainer.value as any)?.childNodes,
    ).map((furtherInfosChildGroup: any) => {
        return Array.from(furtherInfosChildGroup?.childNodes).map(
            (furtherInfosSecondLvlChildNodes: any) => {
                const secondLvlChildNodes = Array.from(
                    furtherInfosSecondLvlChildNodes?.childNodes,
                );
                if (secondLvlChildNodes.length > 1) {
                    return secondLvlChildNodes;
                }
                return furtherInfosSecondLvlChildNodes;
            },
        );
    });

    menuTl.fromTo(
        furtherInfosChildNodes,
        {
            opacity: 0,
            x: -40,
        },
        {
            opacity: 1,
            x: 0,
            stagger: 0.03,
        },
        0.5,
    );
};

// Hooks
onMounted(() => {
    initializeAnimation();
});
</script>

<style scoped>
.navigationMenu-enter-active,
.navigationMenu-leave-active {
    transition: opacity 2s ease;
}

.navigationMenu-enter-from,
.navigationMenu-leave-to {
    opacity: 1;
}
</style>
