<template>
    <div class="flex flex-col items-start flex-grow-0 gap-y-1">
        <a
            class="underline transition-colors hover:text-gray-300"
            href="https://github.com/raphaelbernhart"
            target="_blank"
            rel="noopener noreferrer"
        >
            Github
        </a>
        <a
            class="underline transition-colors hover:text-gray-300"
            href="https://dribbble.com/raphaelbernhart"
            target="_blank"
            rel="noopener noreferrer"
        >
            Dribbble
        </a>
        <a
            class="underline transition-colors hover:text-gray-300"
            href="https://vimeo.com/raphaelbernhart"
            target="_blank"
            rel="noopener noreferrer"
        >
            Vimeo
        </a>
    </div>
</template>

<script lang="ts" setup></script>

<style></style>
