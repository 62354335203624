<template>
    <nuxt-link
        :to="localePath(route)"
        class="relative cursor-pointer"
        @click="handleSamePageClick"
    >
        <div id="text-container" class="relative">
            <span
                id="text-stroke-sibling"
                class="relative z-30 inline-block duration-500 font-display text-7xl text-primary hover:text-transparent xs:text-7xl xl:text-9xl no-ligature"
            >
                {{ text }}
            </span>
            <span
                class="absolute top-0 left-0 z-40 inline-block text-transparent transition-colors pointer-events-none text-stroke font-display text-7xl xs:text-7xl xl:text-9xl no-ligature"
            >
                {{ text }}
            </span>
            <span
                id="animation-text"
                class="absolute top-0 left-0 z-20 block text-transparent bg-cover pointer-events-none bg-gradient-to-br from-green-800 to-indigo-900 bg-clip-text font-display text-7xl xs:text-7xl xl:text-9xl no-ligature"
            >
                {{ text }}
            </span>
        </div>
    </nuxt-link>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
const navigationStore = useNavigationStore();

const props = defineProps({
    text: {
        type: String,
        required: true,
    },
    route: {
        type: String,
        required: true,
    },
});

const handleSamePageClick = () => {
    const currentRoute = useRoute();

    if (currentRoute.path === localePath(props.route)) {
        navigationStore.closeNavigation();
    }
};
</script>

<style>
.text-stroke {
    opacity: 0;
    transition: 400ms;
    transition-property: opacity;
    -webkit-text-stroke: 1px white;
}

#text-stroke-sibling:hover + .text-stroke {
    opacity: 1;
    transition: 400ms;
}

#animation-text {
    animation: none;
}

#text-container:hover #animation-text {
    animation-iteration-count: infinite;
    animation: animate 1s;
}

@keyframes animate {
    0% {
        -webkit-clip-path: polygon(
            0 100%,
            0 100%,
            0 100%,
            0 100%,
            0 100%,
            0 100%,
            0 100%,
            0 100%,
            0 100%,
            0 100%
        );
        clip-path: polygon(
            0 100%,
            0 100%,
            0 100%,
            0 100%,
            0 100%,
            0 100%,
            0 100%,
            0 100%,
            0 100%,
            0 100%
        );
        animation-delay: 0;
        animation-timing-function: ease-in;
    }

    50% {
        -webkit-clip-path: polygon(
            0 100%,
            0 22%,
            21% 14%,
            38% 17%,
            54% 21%,
            66% 35%,
            71% 51%,
            75% 69%,
            84% 81%,
            100% 100%
        );
        clip-path: polygon(
            0 100%,
            0 22%,
            21% 14%,
            38% 17%,
            54% 21%,
            66% 35%,
            71% 51%,
            75% 69%,
            84% 81%,
            100% 100%
        );
        animation-delay: 0;
        animation-timing-function: linear;
    }

    100% {
        -webkit-clip-path: polygon(
            0 100%,
            0 0,
            26% 0,
            53% 0,
            80% 0,
            100% 0,
            100% 19%,
            100% 46%,
            100% 67%,
            100% 100%
        );
        clip-path: polygon(
            0 100%,
            0 0,
            26% 0,
            53% 0,
            80% 0,
            100% 0,
            100% 19%,
            100% 46%,
            100% 67%,
            100% 100%
        );
        animation-delay: 0;
        animation-timing-function: ease-out;
    }
}
</style>
