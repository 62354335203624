<template>
    <div
        class="relative overflow-x-hidden selection:text-gray-400 selection:bg-light"
    >
        <!-- <LocomotiveScroll
            ref="scroller"
            :getted-options="{
                smooth: true,
                direction: 'vertical',
            }"
        > -->
        <lenis ref="lenisRef">
            <div
                ref="nuxtStartupTransitionContainer"
                class="w-screen min-h-screen transition-opacity duration-75 bg-white"
            >
                <Navigation />
                <SocialMediaFixed />
                <div data-page>
                    <NuxtPage />
                </div>
                <Footer data-scroll-section />
            </div>
        </lenis>
        <!-- </LocomotiveScroll> -->
        <!-- <Transition v-if="!config.dev" /> -->
    </div>
</template>

<script setup lang="ts">
const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true,
});
</script>

<style>
* {
    font-synthesis: none;
    -moz-font-feature-settings: 'kern';
    font-feature-settings: 'kern';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.no-ligature {
    -moz-font-feature-settings: 'liga' off;
    font-feature-settings: 'liga' off;
}
.underline-offset-3 {
    text-underline-offset: 3px;
}
.FADE_UP {
    opacity: 0;
}
.FADE_LEFT {
    opacity: 0;
}
.FADE_UP_IMAGE img {
    opacity: 0;
}
</style>
